body {
  font-size: 14px;
}

.tera-page {
  padding: 10px;
}

.tera-page-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-header {
    box-shadow: 0px 2px 2px 0px rgba(17, 24, 39, 0.1);
    padding: 15px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
    align-items: initial;
    @include respond('tablet') {
      flex-direction: row;
      align-items: center;
    }

    &__breadcrumb {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &__function {
      display: flex;
      gap: 10px;
    }

    &-btn {
      padding-block: 5px;
      max-width: 200px;
    }
  }

  .page-header-sticky {
    // sticky top-[45px] bg-[#F3F3F9] z-10
    position: sticky;
    top: 45px;
    background-color: #f3f3f9;
    z-index: 10;
  }

  .page-header-v2 {
    height: 50px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: #fff;
    align-items: initial;

    @include respond('tablet') {
      flex-direction: row;
      align-items: center;
    }

    &__breadcrumb {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &__function {
      display: flex;
      gap: 10px;
    }

    &-btn {
      padding-block: 4px;
    }
  }

  .page-content-v2 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 16px;
    .page-content-block-v2 {
      padding: 16px;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.03),
        0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }

    .page-content-block-heading-v2 {
      @extend .page-content-block-v2;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .page-content-block-title {
      span:first-child {
        color: rgba(14, 159, 110, 1);
      }
      span:last-child {
        color: rgba(55, 65, 81, 1);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .page-content {
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex: 1;
    box-shadow: 0px 2px 2px 0px rgba(17, 24, 39, 0.1);
  }
}

.page-breadcrumb {
  display: flex;
  gap: 10px;
  align-items: center;
}

.modal-fullscreen {
  width: 100% !important;
  height: 100vh;

  &.tera-modal {
    margin: 0 !important;

    .tera-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 100vh;
      width: 100%;
      border-radius: 0;
      overflow: hidden;

      .tera-modal-header {
        flex-shrink: 0;
      }
      .tera-modal-body {
        overflow: auto;
        flex: 1;
      }
      .tera-modal-footer {
        flex-shrink: 0;
        padding-top: 10px;
        padding-bottom: 30px;
        padding-inline: 0;
        margin-inline: 30px;
        border-top: 1px solid #e9e9e9;
      }
    }
  }
}

.drag-modal-fullscreen {
  display: flex;
  width: 100% !important;
  height: 100vh;
  transform: none !important;
  .tera-modal {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    .tera-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 0;
      overflow: hidden;

      .tera-modal-header {
        flex-shrink: 0;
      }
      .tera-modal-body {
        overflow: auto;
        flex: 1;
      }
      .tera-modal-footer {
        flex-shrink: 0;
        padding-top: 10px;
        padding-bottom: 30px;
        padding-inline: 0;
        margin-inline: 30px;
        border-top: 1px solid #e9e9e9;
      }
    }
  }
}

.modal-preview {
  width: 90vw !important;
  &.tera-modal {
    margin: 0 !important;

    .tera-modal-content {
      display: flex;
      flex-direction: column;
      height: 90vh;
      width: 90vw;
      border-radius: 0;
    }

    .tera-modal-body {
      flex: 1;
      padding: 10px !important;
      overflow: auto;
    }
  }

  &__body {
    overflow: hidden;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: 100%;
      max-height: 100%;
      object-fit: contain;
      display: flex;
      margin: auto;
    }

    iframe,
    object {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    video {
      height: 100%;
      width: 100%;
    }
  }
}

.modal-confirm {
  .tera-modal-footer {
    justify-content: center;
    border-top: none;
    padding-top: 0;
  }
}

.tab-table {
  font-weight: 500;
  // text-transform: uppercase;
  font-size: 13px;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.tab-count {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-weight: 400;
  border-radius: 50%;
  background-color: #bcf0da;
  color: #3f83f8;
}

.detail-key {
  min-width: 170px;
  max-width: 170px;
  font-size: 13px;
  line-height: 20px;
  color: #6b7280;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.detail-value {
  font-size: 13px;
  line-height: 20px;
  color: #374151;

  max-height: 150px;
  overflow-y: scroll;
  word-break: break-word;

  &-more {
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    word-break: break-all;
  }
}

.content-html {
  p,
  span,
  h3 {
    padding: 10px 0px;
  }
  figure {
    height: auto;
    width: 100%;
  }
}

.text-custom-table {
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .detail-responsive {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      'item1 item5 '
      'item4 item3 '
      'item7 item8'
      'item10 item6'
      'item2 item9'
      'item11 item12';

    @for $i from 1 through 12 {
      .item#{$i} {
        grid-area: item#{$i};
      }
    }
  }
}

.purchase-table-detail {
  table {
    width: 100%;
    border-collapse: collapse;
  }
  tr,
  td {
    border: 1px solid rgba(227, 227, 227, 0.758);
  }
  td {
    padding: 0.625rem;
    // min-width: 150px;
    word-break: break-all;
  }
  .title {
    background-color: rgb(249 250 251);
    font-weight: 600;
    width: 150px;
  }
}

.mail-sidebar-item:hover {
  p {
    color: #2563eb;
  }
  svg {
    fill: #2563eb;
  }
}

.mail-table {
  tbody {
    tr {
      border-bottom: 1px solid #f3f4f6;
      cursor: pointer;

      &:hover {
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
      }

      td {
        padding: 30px 12px;
      }
    }
  }
}

.react-flow .react-flow__handle.custom-handle {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #9ca3af;
  z-index: 1;
}

.kanban-default {
  position: relative;
  overflow: hidden;
  background-color: inherit;
  visibility: hidden;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    content: '';
  }
}
.fadeOut {
  animation: fadeOut 1s forwards;
}
.fadeIn {
  animation: fadeIn 0.3s forwards;
}
.fadeIn-left {
  animation: fadeIn-left 0.3s forwards;
}

.fadeOut_left {
  animation: fadeOut-left 1s forwards;
}

.fadeOut_right {
  animation: fadeOut-right 1s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    display: block;
  }
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn-left {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut-left {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-50%);
  }
}

@keyframes fadeOut-right {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}

.mail-config__item {
  & > div > div {
    width: 100%;
  }

  & > div {
    overflow: unset;
  }

  &--active {
    position: relative;
    background-color: #3f83f8;
    color: #fff !important;

    &::before {
      position: absolute;
      content: '';
      width: 8px;
      height: 16px;

      top: 50%;
      left: 100%;
      background-color: #3f83f8;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      transform: translate(0, -50%);
    }
  }
}

.custom-outline-none {
  outline: none !important;
}

.table-summary {
  background-color: #f7f9fa;

  td {
    padding: 16px 12px;

    &.text-primary {
      font-weight: 500;
    }
  }
}

.font-libre-franklin {
  font-family: Libre Franklin;
}

.tailwind-preflight {
  h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }

  ul,
  ol {
    margin-left: 1.5rem;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }
}

.stage-menu {
  text-align: left;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #f1f1f1;

  li {
    font-size: 8px;
    padding: 2px 4px;
    line-height: 12px;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

div#wave {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
div#wave .dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 3px;
  background: #333;
  animation: wave 1.2s linear infinite;
}
div#wave .dot:nth-child(2) {
  animation-delay: -1s;
}
div#wave .dot:nth-child(3) {
  animation-delay: -0.8s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}

.tera-menu {
  &--active .tera-menu__item,
  &:hover .tera-menu__item {
    path {
      fill: #2563eb;
    }
  }

  &-mini_icon svg path {
    fill: #fff;
  }
}

.break-word {
  word-break: break-word;
}

.tera-modal-content {
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  .tera-modal-body {
    overflow-y: auto;
  }
}
.center-table {
  .tera-table-cell {
    vertical-align: middle !important;
  }
}

.over-view-left {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.14);
  background-image: url(../images/Affiliates/aff_bg_left.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.over-view-sell {
  .box-content {
    position: relative;
    z-index: 2;
  }
}

.tear-custom {
  position: absolute;
  top: 0;
  z-index: 1;
}
.break-word {
  word-break: break-word;
}
